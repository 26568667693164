<template>
  <div>
    <my-nav-bar
      title="提成明细"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container commission-statements">
      <van-sticky offset-top="1.22667rem">
        <van-dropdown-menu active-color="#1989fa" :close-on-click-outside="false">
          <van-dropdown-item ref="dropdownItem" title="筛选">
            <van-cell title="开始日期" :value="searchQuery.start_date" is-link center @click="handleShowDatePicker('start_date')" />
            <van-cell title="结束日期" :value="searchQuery.end_date" is-link center @click="handleShowDatePicker('end_date')" />
            <van-cell title="提成类型" :value="searchQuery.type_name" is-link center @click="showTypeActionSheet = true" />
            <van-cell title="合并统计" center>
              <van-switch v-model="searchQuery.is_total" size="24px" :active-value="1" :inactive-value="0" />
            </van-cell>
            <div>
              <van-button type="warning" style="display: inline-block; width: 50%" square @click="handleReset">重置</van-button>
              <van-button type="primary" style="display: inline-block; width: 50%" square @click="handleSearch">确认</van-button>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </van-sticky>

      <van-empty v-if="showEmpty" description="暂无数据" />

      <van-list ref="list" v-model="loading" class="list" :finished="finished" @load="getList">
        <div v-for="(commissionStatement, index) in list" :key="index" class="commission-statement-item-container">
          <commission-statement-total v-if="listQuery.is_total" :commission-statement="commissionStatement" />
          <commission-statement v-else :commission-statement="commissionStatement" />
        </div>
      </van-list>

      <van-popup v-model="showDatePicker" position="bottom" safe-area-inset-bottom>
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          @confirm="handleDatePickerSeleted"
          @cancel="showDatePicker = false"
        />
      </van-popup>

      <van-action-sheet v-model="showTypeActionSheet" :actions="typeActions" @select="handleActionSeleted" />

    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getCommissionDetail } from '@/api/commission'
import { toDateString } from '@/utils'
import CommissionStatement from './components/commission-statement'
import CommissionStatementTotal from './components/commission-statement-total'
export default {
  components: { myNavBar, CommissionStatement, CommissionStatementTotal },
  data() {
    return {
      routeQuery: { ...this.$route.query },
      listQuery: {
        page: 0,
        start_date: undefined,
        end_date: undefined,
        type: undefined,
        is_total: 0
      },
      searchQuery: {
        start_date: undefined,
        end_date: undefined,
        type_name: undefined,
        type: undefined,
        is_total: 0
      },
      currentDate: new Date(),
      list: [],
      loading: false,
      finished: false,
      showEmpty: false,
      showDatePicker: false,
      datePickerType: '',
      showTypeActionSheet: false,
      typeActions: [
        { name: '销售', value: 1 },
        { name: '退货', value: 2 }
      ]
    }
  },
  methods: {
    handleReset() {
      this.searchQuery = {
        start_date: undefined,
        end_date: undefined,
        type_name: undefined,
        type: undefined,
        is_total: 0
      }
    },
    handleSearch() {
      this.listQuery.start_date = this.searchQuery.start_date
      this.listQuery.end_date = this.searchQuery.end_date
      this.listQuery.type = this.searchQuery.type
      this.listQuery.is_total = this.searchQuery.is_total
      this.reload()
      this.$refs.dropdownItem.toggle(false)
    },
    reload() {
      this.loading = false
      this.finished = false
      this.showEmpty = false
      this.list = []
      this.listQuery.page = 0
      this.$refs.list.check()
    },
    getList() {
      this.listQuery.page++
      // 统计汇总
      const params = {
        ...this.listQuery,
        ...this.routeQuery
      }
      getCommissionDetail(params).then(res => {
        this.loading = false
        this.finished = res.data.list.length < 10
        this.list = this.list.concat(res.data.list)
        this.showEmpty = this.list.length === 0
      })
    },
    handleShowDatePicker(type) {
      this.datePickerType = type
      this.showDatePicker = true
    },
    handleDatePickerSeleted(dateObj) {
      this.searchQuery[this.datePickerType] = toDateString(dateObj)
      this.showDatePicker = false
    },
    handleActionSeleted(action) {
      this.searchQuery.type_name = action.name
      this.searchQuery.type = action.value
      this.showTypeActionSheet = false
    }
  }
}
</script>

<style lang="scss">
  .commission-statements {
    .van-dropdown-menu {
      &__bar {
        height: 40px!important;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .list {
    padding: 10px;
  }
  .commission-statement-item-container {
    margin-bottom: 10px;
  }
  .commission-statement-item-container:nth-last-child(2) {
    margin-bottom: 0;
  }
</style>
